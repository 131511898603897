<template>
  <div id="app" class="main" :class="appClasses">
    <div class="main-inner">
      <ToolBar
        :stats="stats"
        :panels="panels"
        :settings="settings"
        @openPanel="openPanel"
      />

      <ToastMsg :toastMsg="toastMsg" />

      <div class="panels">
        <transition name="fade">
          <div v-if="panels.all.help" class="panel help">
            <div class="panel-close" @click="closePanel('help')">
              <svg class="svg-icon"><use xlink:href="#svg-close" /></svg>
            </div>
            <div class="panel-content">
              <h2 class="panel-title">Règles du jeu</h2>
              <p>Le but est de trouver un mot de cinq lettres.</p>
              <p>Vous avez droit à six essais. Chaque essai doit être un mot de cinq lettres. Appuyez sur la touche <span class="key"><svg class="svg-icon inline"><use xlink:href="#svg-check" /></svg></span> pour valider votre essai.</p>
              <p>À chaque essai, le jeu vous indique si les lettres de votre mot sont présentes dans le mot recherché&nbsp;:</p>
              <ul class="help-samples">
                <li><span class="sample-letter v2">A</span> La lettre est présente dans le mot et à la bonne place.</li>
                <li><span class="sample-letter v1">A</span> La lettre est présente dans le mot, mais pas à la bonne place.</li>
                <li><span class="sample-letter v0">A</span> La lettre n'est pas présente dans le mot.</li>
              </ul>

              <p>Le mot à trouver est le même pour tout le monde.<br>
                <span class="accent">Un nouveau mot est tiré au hasard une fois par jour.</span></p>

              <h3>Astuces</h3>
              <p>Si vous avez un clavier physique, vous pouvez utiliser celui-ci pour saisir votre mot.</p>
              <p v-if="settings.showWildcardKey">La touche <span class="key">*</span> (astérisque) n'a aucun effet, mais vous pouvez la placer temporairement dans votre mot comme caractère-joker si cela vous aide à visualiser des mots possibles.</p>
              <p><a @click="openPanel('settings')">Visitez les paramètres</a> pour modifier le layout du clavier ou changer le thème, entre autres options.</p>

              <div class="copyright-notice">
                <p>Ce jeu est une re-création du fameux <a href="https://www.powerlanguage.co.uk/wordle/" target="_blank">Wordle</a>, en langue française.</p>
                <p>Développement et design&nbsp;: <a href="https://twitter.com/s427" target="_blank">@s427</a>, 2022</p>
                <p>Concept original&nbsp;: <a href="https://twitter.com/powerlanguish" target="_blank">@powerlanguish</a> (Josh Wardle)</p>
              </div>
            </div>
          </div>
        </transition>

        <transition name="fade">
          <div v-if="panels.all.settings" class="panel settings">
            <div class="panel-close" @click="closePanel('settings')">
              <svg class="svg-icon"><use xlink:href="#svg-close" /></svg>
            </div>
            <div class="panel-content">
              <h2 class="panel-title">Paramètres</h2>

              <div class="settings-group setting-checkbox" @click="changeSettings('showWildcardKey')" :class="settings.showWildcardKey ? 'on' : ''">
                <div class="setting-btn">
                  <svg v-if="settings.showWildcardKey" class="svg-icon"><use xlink:href="#svg-checkbox-checked" /></svg>
                  <svg v-if="!settings.showWildcardKey" class="svg-icon"><use xlink:href="#svg-checkbox-unchecked" /></svg>
                </div>
                <div class="setting-label">
                  Afficher la touche "astérisque" (*)
                </div>
                <div class="setting-note">
                  Vous permet d'insérer un caractère-joker dans votre mot. Cela n'a aucun effet (vous ne pouvez pas envoyer le mot) mais cela peut vous aider à visualiser des mots possibles.
                </div>
              </div>

              <h3>Thème</h3>

              <div class="settings-group setting-multiple">
                <div class="setting" @click="changeSettings('theme', 'dark')" :class="settings.theme == 'dark' ? 'on' : ''">
                  <div class="setting-btn">
                    <svg v-if="settings.theme == 'dark'" class="svg-icon"><use xlink:href="#svg-radio-checked" /></svg>
                    <svg v-if="settings.theme != 'dark'" class="svg-icon"><use xlink:href="#svg-radio-unchecked" /></svg>
                  </div>
                  <div class="setting-label">
                    Sombre
                  </div>
                </div>
                <div class="setting" @click="changeSettings('theme', 'light')" :class="settings.theme == 'light' ? 'on' : ''">
                  <div class="setting-btn">
                    <svg v-if="settings.theme == 'light'" class="svg-icon"><use xlink:href="#svg-radio-checked" /></svg>
                    <svg v-if="settings.theme != 'light'" class="svg-icon"><use xlink:href="#svg-radio-unchecked" /></svg>
                  </div>
                  <div class="setting-label">
                    Clair
                  </div>
                </div>
              </div>

              <h3>Accessibilité</h3>

              <div class="settings-group setting-checkbox" @click="changeSettings('colorBlind')" :class="settings.colorBlind ? 'on' : ''">
                <div class="setting-btn">
                  <svg v-if="settings.colorBlind" class="svg-icon"><use xlink:href="#svg-checkbox-checked" /></svg>
                  <svg v-if="!settings.colorBlind" class="svg-icon"><use xlink:href="#svg-checkbox-unchecked" /></svg>
                </div>
                <div class="setting-label">
                  Mode daltonien
                </div>
              </div>

              <div class="settings-group setting-checkbox" @click="changeSettings('reducedMotion')" :class="settings.reducedMotion ? 'on' : ''">
                <div class="setting-btn">
                  <svg v-if="settings.reducedMotion" class="svg-icon"><use xlink:href="#svg-checkbox-checked" /></svg>
                  <svg v-if="!settings.reducedMotion" class="svg-icon"><use xlink:href="#svg-checkbox-unchecked" /></svg>
                </div>
                <div class="setting-label">
                  Sans animations
                </div>
              </div>

              <h3>Clavier</h3>

              <div class="settings-group setting-multiple">
                <div class="setting" @click="changeSettings('keyboardLayout', 'QWERTZ')"  :class="settings.keyboardLayout == 'QWERTZ' ? 'on' : ''">
                  <div class="setting-btn">
                    <svg v-if="settings.keyboardLayout == 'QWERTZ'" class="svg-icon"><use xlink:href="#svg-radio-checked" /></svg>
                    <svg v-if="settings.keyboardLayout != 'QWERTZ'" class="svg-icon"><use xlink:href="#svg-radio-unchecked" /></svg>
                  </div>
                  <div class="setting-label">
                    QWERTZ
                  </div>
                </div>
                <div class="setting" @click="changeSettings('keyboardLayout', 'QWERTY')"  :class="settings.keyboardLayout == 'QWERTY' ? 'on' : ''">
                  <div class="setting-btn">
                    <svg v-if="settings.keyboardLayout == 'QWERTY'" class="svg-icon"><use xlink:href="#svg-radio-checked" /></svg>
                    <svg v-if="settings.keyboardLayout != 'QWERTY'" class="svg-icon"><use xlink:href="#svg-radio-unchecked" /></svg>
                  </div>
                  <div class="setting-label">
                    QWERTY
                  </div>
                </div>
                <div class="setting" @click="changeSettings('keyboardLayout', 'AZERTY')"  :class="settings.keyboardLayout == 'AZERTY' ? 'on' : ''">
                  <div class="setting-btn">
                    <svg v-if="settings.keyboardLayout == 'AZERTY'" class="svg-icon"><use xlink:href="#svg-radio-checked" /></svg>
                    <svg v-if="settings.keyboardLayout != 'AZERTY'" class="svg-icon"><use xlink:href="#svg-radio-unchecked" /></svg>
                  </div>
                  <div class="setting-label">
                    AZERTY
                  </div>
                </div>
              </div>

              <h3>Mode infini</h3>

              <div class="settings-group setting-checkbox" @click="changeSettings('infinityMode')" :class="settings.infinityMode ? 'on' : ''">
                <div class="setting-btn">
                  <svg v-if="settings.infinityMode" class="svg-icon"><use xlink:href="#svg-checkbox-checked" /></svg>
                  <svg v-if="!settings.infinityMode" class="svg-icon"><use xlink:href="#svg-checkbox-unchecked" /></svg>
                </div>
                <div class="setting-label">
                  Mode infini
                </div>
                <div class="setting-note">
                  Vous permet de deviner autant de mots que vous le souhaitez sans avoir besoin d'attendre une journée. Les statistiques sont stockées séparément du jeu normal.<br>
                  <strong class="accent">Différences avec le mode normal</strong> : le partage est désactivé; le mot en cours n'est pas sauvegardé si vous quittez ou rechargez la page.
                </div>
              </div>

            </div>
          </div>
        </transition>

        <transition name="fade">
          <div v-if="panels.all.summary" class="panel summary">
            <div class="panel-close" @click="closePanel('summary')">
              <svg class="svg-icon"><use xlink:href="#svg-close" /></svg>
            </div>

            <!-- mode normal -->
            <div class="panel-content" v-if="!settings.infinityMode">

              <h2 class="panel-title">WORDLE<span class="accent">·FR</span>
                <template v-if="game.id"> #{{ game.id }}</template>
              </h2>
              <div class="current-wordle">
                <div class="answer-wrap">
                  <div class="answer" :class="game.success ? 'success' : ''">
                    <span v-for="(letter, i) in game.answer" :key="i" class="sample-letter">{{ letter }}</span>
                  </div>
                  <div class="congrats" :style="{visibility: (game.success ? 'visible' : 'hidden')}">
                    {{ congratMsg }} 🎉
                  </div>
                  <div class="share-wordle" v-if="game.finished">
                    <span class="share-btn" @click="shareWordle">
                      <template v-if="!clipboardCopied">
                        <svg class="svg-icon"><use xlink:href="#svg-share" /></svg>
                        Partager
                      </template>
                      <template v-if="clipboardCopied">
                        <svg class="svg-icon"><use xlink:href="#svg-check" /></svg>
                        Copié !
                      </template>
                    </span>
                  </div>
                </div>
                <div class="word-links" v-if="game.answer && game.answer != '_____'">
                  <p>Et ça veut dire&nbsp;?</p>
                  <p><a :href="'https://www.cnrtl.fr/definition/' + game.answer.toLowerCase()" target="_blank">CNRTL.fr</a></p>
                  <p><a :href="'https://1mot.net/' + game.answer.toLowerCase()" target="_blank">1mot.net</a></p>
                </div>
              </div>

              <div class="next-wordle" :style="{visibility: (game.finished && countdown.text ? 'visible' : 'hidden')}">
                Prochaine partie dans <span class="countdown">{{ countdown.text }}</span>.<br>
              </div>

              <div class="your-stats">
                <h2 class="stats-title">Vos statistiques de jeu</h2>
                <div class="user-stats">
                  <p>{{ stats.games }} partie<template v-if="stats.games > 1">s</template> jouée<template v-if="stats.games > 1">s</template><br>
                    {{ stats.victories }} partie<template v-if="stats.victories > 1">s</template> gagnée<template v-if="stats.victories > 1">s</template> ({{ stats.victoriesPc }}%)
                  </p>
                  <p class="victories-icons">
                    <template v-if="stats.victories <  10"></template>
                    <template v-if="stats.victories >= 10 && stats.victories < 20">💪</template>
                    <template v-if="stats.victories >= 20 && stats.victories < 30">💪💪</template>
                    <template v-if="stats.victories >= 30 && stats.victories < 40">💪💪💪</template>
                    <template v-if="stats.victories >= 40 && stats.victories < 50">💪💪💪💪</template>
                    <template v-if="stats.victories >= 50"                        >💪💪💪💪💪</template>
                  </p>
                  <p>Série actuelle : {{ stats.currentStreak }}<br>
                    Meilleure série : {{ stats.bestStreak }}
                    <template v-if="stats.bestStreak > 0  && stats.bestStreak <=  2">🙂</template>
                    <template v-if="stats.bestStreak > 2  && stats.bestStreak <=  4">😃</template>
                    <template v-if="stats.bestStreak > 4  && stats.bestStreak <=  6">😄</template>
                    <template v-if="stats.bestStreak > 6  && stats.bestStreak <=  8">😆</template>
                    <template v-if="stats.bestStreak > 8  && stats.bestStreak <= 10">🤩</template>
                    <template v-if="stats.bestStreak > 10"                          >😎</template>
                  </p>
                  <div class="stats-distributions">
                    <div
                      v-for="(tries, i) in stats.tries"
                      :key="i"
                      class="stats-distribution"
                      :class="stats.bestTriesIndex == i ? 'best' : ''"
                    >
                      <div class="label" v-if="i < 6">{{ i+1 }} <span>essai</span></div>
                      <div class="label fail" v-if="i == 6">💀</div>
                      <div class="pc" :style="{'width': ((stats.tries[i] / stats.games) * 100) + '%'}"></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <!-- mode infini -->
            <div class="panel-content" v-if="settings.infinityMode">

              <h2 class="panel-title">WORDLE<span class="accent">·FR</span>
                <template> #{{ infinity.finished ? stats.infinity.games : stats.infinity.games + 1 }}</template>
              </h2>
              <div class="current-wordle">
                <div class="answer-wrap">
                  <div class="answer" :class="(settings.infinityMode && infinity.previousSuccess) ? 'success' : ''">
                    <span class="sample-letter">{{ infinity.answer[0] }}</span>
                    <span class="sample-letter">{{ infinity.answer[1] }}</span>
                    <span class="sample-letter">{{ infinity.answer[2] }}</span>
                    <span class="sample-letter">{{ infinity.answer[3] }}</span>
                    <span class="sample-letter">{{ infinity.answer[4] }}</span>
                  </div>
                  <div class="congrats" :style="{visibility: (infinity.previousSuccess && congratMsg ? 'visible' : 'hidden')}">
                    {{ congratMsg }} 🎉
                  </div>
                  <div class="infinity-next-btn" :style="{visibility: (infinity.finished ? 'visible' : 'hidden')}">
                    <span class="next-btn" @click="infinityNext">
                      Mot suivant !
                    </span>
                  </div>
                </div>
                <div class="word-links" v-if="infinity.answer && infinity.answer != '_____'">
                  <p>Et ça veut dire&nbsp;?</p>
                  <p><a :href="'https://www.cnrtl.fr/definition/' + infinity.answer.toLowerCase()" target="_blank">CNRTL.fr</a></p>
                  <p><a :href="'https://1mot.net/' + infinity.answer.toLowerCase()" target="_blank">1mot.net</a></p>
                </div>
              </div>

              <div class="your-stats infinity-stats">
                <h2 class="stats-title">Vos statistiques de jeu <span class="mode">mode INFINI</span></h2>
                <div class="user-stats">
                  <p>{{ stats.infinity.games }} partie<template v-if="stats.infinity.games > 1">s</template> jouée<template v-if="stats.infinity.games > 1">s</template><br>
                    {{ stats.infinity.victories }} partie<template v-if="stats.infinity.victories > 1">s</template> gagnée<template v-if="stats.infinity.victories > 1">s</template> ({{ stats.infinity.victoriesPc }}%)
                  </p>
                  <p class="victories-icons">
                    <template v-if="stats.infinity.victories <  10"></template>
                    <template v-if="stats.infinity.victories >= 10 && stats.infinity.victories < 20">💪</template>
                    <template v-if="stats.infinity.victories >= 20 && stats.infinity.victories < 30">💪💪</template>
                    <template v-if="stats.infinity.victories >= 30 && stats.infinity.victories < 40">💪💪💪</template>
                    <template v-if="stats.infinity.victories >= 40 && stats.infinity.victories < 50">💪💪💪💪</template>
                    <template v-if="stats.infinity.victories >= 50"                                 >💪💪💪💪💪</template>
                  </p>
                  <p>Série actuelle : {{ stats.infinity.currentStreak }}<br>
                    Meilleure série : {{ stats.infinity.bestStreak }}
                    <template v-if="stats.infinity.bestStreak > 0  && stats.infinity.bestStreak <= 10">🙂</template>
                    <template v-if="stats.infinity.bestStreak > 10 && stats.infinity.bestStreak <= 20">😃</template>
                    <template v-if="stats.infinity.bestStreak > 20 && stats.infinity.bestStreak <= 30">😄</template>
                    <template v-if="stats.infinity.bestStreak > 30 && stats.infinity.bestStreak <= 40">😆</template>
                    <template v-if="stats.infinity.bestStreak > 40 && stats.infinity.bestStreak <= 50">🤩</template>
                    <template v-if="stats.infinity.bestStreak > 50"                                   >😎</template>
                  </p>
                  <div class="stats-distributions">
                    <div
                      v-for="(tries, i) in stats.infinity.tries"
                      :key="i"
                      class="stats-distribution"
                      :class="stats.infinity.bestTriesIndex == i ? 'best' : ''"
                    >
                      <div class="label" v-if="i < 6">{{ i+1 }} <span>essai</span></div>
                      <div class="label fail" v-if="i == 6">💀</div>
                      <div class="pc" :style="{'width': ((stats.infinity.tries[i] / stats.infinity.games) * 100) + '%'}"></div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="normal-wordle-status" v-if="countdown.text">
                <template v-if="game.finished && countdown.text">
                  <h4>🐌 Des nouvelles du monde extérieur 🐌</h4>
                  <p>Prochaine partie en mode NORMAL dans <span class="countdown">{{ countdown.text }}</span>.</p>
                  <p class="accent" v-if="countdown.time < 60">Préparez-vous&nbsp;!</p>
                </template>

                <template v-if="!game.finished">
                  <h4>🐌 Des nouvelles du monde extérieur 🐌</h4>
                  <p>Une nouvelle partie en mode NORMAL est disponible&nbsp;!</p>
                  <p><a @click="openPanel('settings')">Désactivez le mode INFINI</a> pour participer&nbsp;!</p>
                </template>
              </div>
            </div>
          </div>
        </transition>
      </div>

      <div class="user-entries">
        <UserEntry
          v-for="index in 6"
          :key="index"
          :line="index-1"
          :words="words"
          :currentLine="settings.infinityMode ? infinity.currentLine : game.currentLine"
          :input="userInput"
        />
      </div>

      <div class="keyboard">
        <div class="keyboard-row"
          v-for="(row, i) in keyboards[settings.keyboardLayout]"
          :key="i">
          <KeyboardKey
            v-for="(key, j) in row"
            :key="j"
            :k="key"
            :c="keys[key]"
            :wildcard="settings.showWildcardKey"
            @keyPressed="keyPressed"
          />
        </div>
      </div>

      <div class="input" style="display: none;">
        {{ userInput }} <!-- si on retire ça, le clavier ne fonctionne plus ¯\_(ツ)_/¯ -->
      </div>

      <SVGIcons />
    </div>
  </div>
</template>

<script>
  import ToolBar from './components/ToolBar.vue';
  import ToastMsg from './components/ToastMsg.vue';
  import SVGIcons from './components/SVGIcons.vue';
  import UserEntry from './components/UserEntry.vue';
  import KeyboardKey from './components/KeyboardKey.vue';

  export default {
    name: 'App',
    components: {
      ToolBar,
      ToastMsg,
      SVGIcons,
      UserEntry,
      KeyboardKey,
    },
    data: function () {
      return {
        // statique
          gameUrl: process.env.VUE_APP_GAMEURL,
          serverRoot: process.env.VUE_APP_SERVERROOT,
          nbTries: 6,
          wordLength: 5,
          localStorageVar: 'wordlefr',
          congratMsgs: {
            1: [ // 1er essai
              "!!!",
              "MAIS NON ?!",
              "Impressionnant !",
              "Monstre respect !",
              "La grande classe !",
              "Incroyable !",
              "Quel bol !",
              "Vous auriez dû jouer au loto !",
            ],
            2: [ // 2e essai
              "Félicitations !",
              "Respect.",
              "Monstre respect !",
              "Joli !",
              "Très joli !",
              "La classe !",
              "La grande classe !",
              "[Applaudissements]",
              "Bravo !",
              "Bravo bravo !",
              "Bravissimo !",
              "Incroyable !",
              "OK, bien joué.",
            ],
            3: [ // 3-5 essais
              "Félicitations !",
              "Impressionnant !",
              "Respect.",
              "Monstre respect !",
              "Joli !",
              "Très joli !",
              "La classe !",
              "La grande classe !",
              "Bravo !",
              "Bravo bravo !",
              "Bravissimo !",
              "Mais bravo !",
              "Eh ben bravo !",
              "Mais bravo dites donc !",
              "Clap clap clap !",
              "T'es trop fort !",
              "Incroyable !",
              "OK, bien joué.",
              "Bien joué !",
              "GG !",
              "Pas mal, pas mal.",
              "J'avoue j'y croyais pas.",
            ],
            6: [ // 6e essai
              "Ce finish !",
              "Ce finish de malade !",
              "Pfiou !",
              "De justesse !",
              "C'est pas passé loin !",
              "J'avoue j'y croyais pas.",
              "Olalah quel suspense !",
              "On n'y croyait plus !",
              "In extremis !",
            ],
          },
          failureMsgs: [
            "Désolé !",
            "Dommage !",
            "Pas de chance !",
            "Perdu !",
            "Argh, de justesse !",
            "Bon ben voilà...",
            "GAME OVER",
            "YOU DIED",
            "Voilà, c'est fini.",
            "On fera mieux la prochaine fois !",
            "On peut pas gagner tous les jours...",
          ],
          startMsgs: [
            "Go !",
            "Go go go !",
            "On y va !",
            "Allez allez !",
            "Hop !",
            "Hop hop hop !",
            "Suivant !",
            "Au suivant !",
            "Allez hop !",
          ],
          keyboards: {
            QWERTZ: [
              ['Q','W','E','R','T','Z','U','I','O','P'],
              ['A','S','D','F','G','H','J','K','L','*'],
              ['ENTER', 'Y','X','C','V','B','N','M','BACKSPACE'],
            ],
            QWERTY: [
              ['Q','W','E','R','T','Y','U','I','O','P'],
              ['A','S','D','F','G','H','J','K','L','*'],
              ['ENTER', 'Z','X','C','V','B','N','M','BACKSPACE'],
            ],
            AZERTY: [
              ['A','Z','E','R','T','Y','U','I','O','P'],
              ['Q','S','D','F','G','H','J','K','L','M'],
              ['ENTER','W','X','C','V','B','N','*','BACKSPACE'],
            ],
          },
          defaultSettings: {
            showHelpOnLoad: true,
            showWildcardKey: true,
            keyboardLayout: 'QWERTZ',
            theme: 'dark',
            colorBlind: false,
            reducedMotion: false,
            infinityMode: false,
          },

        // runtime
          userInput: '',
          hasWildcards: false,
          isSending: false,
          isDeletingWord: false,
          clipboardCopied: false,
          countdown: {
            time: 0,
            text: '',
            timeout: null,
          },
          appClasses: ['theme-dark', '', '', ''], // 0: theme, 1: colorBlind, 2: reducedMotion, 3: panel-open/closed
          congratMsg: '',
          keys: {},
          panels: {
            all: {
              help: false,
              settings: false,
              summary: false,
            },
            current: '',
            timeout: null,
            layout2: false,
          },
          toastMsg: {
            msg: '',
            timeout: 3000,
            t: null,
          },
          serverData: null,

        // localStorage
          words: [],
          game: {
            id: null,
            answer: '_____',
            finished: false,
            success: false,
            currentLine: 0,
          },
          settings: { // voir defaultSettings si on ajoute quelque chose ici
            showHelpOnLoad: true,
            showWildcardKey: true,
            keyboardLayout: 'QWERTZ',
            theme: 'dark',
            colorBlind: false,
            reducedMotion: false,
            infinityMode: false,
          },
          stats: {
            games: 0,
            victories: 0,
            victoriesPc: 0,
            currentStreak: 0,
            bestStreak: 0,
            tries: [0,0,0,0,0,0,0],
            bestTriesIndex: 0,
            history: [],
            infinity: {
              games: 0,
              victories: 0,
              victoriesPc: 0,
              currentStreak: 0,
              bestStreak: 0,
              tries: [0,0,0,0,0,0,0],
              bestTriesIndex: 0,
              history: [],
            }
          },
          infinity: {
            wordle: '',
            answer: '_____',
            finised: false,
            previousSuccess: false,
            words: [],
            currentLine: 0,
            statsDefault: {
              games: 0,
              victories: 0,
              victoriesPc: 0,
              currentStreak: 0,
              bestStreak: 0,
              tries: [0,0,0,0,0,0,0],
              bestTriesIndex: 0,
              history: [],
            }
          },
      }
    },
    methods: {

      // communications serveur
        sendInput: function () {
          if (this.settings.infinityMode) {
            this.infinityCheck();
            return;
          }

          if (this.isSending) return;
          if (this.game.success) return;

          let word = this.userInput;
          if (word.length != this.wordLength) {
            this.setToastMsg("Veuillez saisir un mot de 5 lettres.");
            return;
          }
          if (~word.indexOf('*')) {
            this.setToastMsg("Le mot ne peut pas contenir d'astérisque (*).");
            return;
          }

          this.isSending = true;
          const axios = require('axios').default;
          let self = this;

          // sécurité liée à changement de nom de propriété (was: currentWord)
          // ## mieux traiter ce genre de cas
          if (! this.game.currentLine) {
            this.game.currentLine = 0;
          }

          axios.post(this.serverRoot + 'server.php', {word: this.game.currentLine + word})
            .then(function (response) {
              let data = response.data;
              self.serverData = data;

              // vérifier l'ID du wordle
              // si pas égal, réinitialiser tout !
              if (self.game.id) {
                if (self.game.id != data.id) {
                  self.setToastMsg("Désolé, ce Wordle a expiré !<br>Un nouveau Wordle est publié toutes les 24 heures.", 6000);
                  self.resetGame();
                  return;
                }
              }
              self.game.id = data.id;

              switch (data.result) {
                case 'tryagain':
                case 'success':
                case 'failure':
                  self.udpateWord();
                  break;
                case 'invalid':
                  self.setToastMsg("Ce mot n'est pas reconnu.");
                  self.deleteWord();
                  break;
              }

              self.initCountdown(data.countdown);
            })
            .catch(function (error) {
              // handle error
              console.log(error);
            })
            .then(function () {
              // exécuté dans tous les cas
              self.isSending = false;
            });
        },
        checkNewWordle: function () {
          if (! this.settings.infinityMode && ! this.game.id) {
            // si on est mode normal et qu'on n'a aucun ID, pas besoin de faire de vérification car on part de zéro
            // si on est en mode infini, on continue.
            return;
          }

          const axios = require('axios').default;
          let self = this;

          axios.get(this.serverRoot + 'server.php?check')
            .then(function (response) {
              let data = response.data;
              if (data.id == self.game.id) {
                // OK
                self.initCountdown(data.countdown);
              } else {
                // nouveau Wordle disponible !
                // tout réinitialiser
                // sauf si on est en mode infini
                if (! self.settings.infinityMode) {
                  self.resetGame();
                  self.game.id = data.id;
                }
                self.initCountdown(data.countdown);
                return;
              }
            })
            .catch(function (error) {
              console.log(error);
            })
        },


      // localStorage
        saveLocalData: function () {
          let data = {
            game: this.game,
            words: this.words,
            settings: this.settings,
            stats: this.stats,
          };
          if (this.settings.infinityMode) {
            // ne pas écraser la valeur de "words" déjà stockée (partie normale)
            let ls = JSON.parse(localStorage.getItem(this.localStorageVar));
            if (ls) {
              data.words = ls.words;
            }
          }
          localStorage.setItem(this.localStorageVar, JSON.stringify(data));
        },
        getLocalData: function () {
          let data = JSON.parse(localStorage.getItem(this.localStorageVar));
          if (data) {
            this.game = data.game;
            this.words = data.words;
            this.settings = data.settings;
            this.stats = data.stats;
            if (! this.stats.infinity) {
              this.stats.infinity = this.infinity.statsDefault;
            }
          }
        },
        saveSettings: function (setting) {
          // récupérer les données en localStorage (sans écraser notre modèle de données actuel)
          // y remplacer settings
          // les restocker
          let data = JSON.parse(localStorage.getItem(this.localStorageVar));
          if (! data) {
            this.saveLocalData();
            this.saveSettings(setting);
            return;
          }
          data.settings = this.settings;
          localStorage.setItem(this.localStorageVar, JSON.stringify(data));

          if (setting == 'infinityMode') {
            if (this.settings.infinityMode) {
              this.infinityStart();
            } else {
              this.infinityStop();
            }
          }
        },


      // gestion des panneaux
        openPanel: function (panel) {
          for (var p in this.panels.all) {
            this.panels.all[p] = false;
          }
          this.panels.all[panel] = true;
          this.panels.current = panel;
          this.appClasses[3] = 'panel-open';

          if (panel != 'help') {
            this.settings.showHelpOnLoad = false;
          }

          if (this.panels.timeout) {
            // annuler l'ouverture prévue d'un autre panneau
            // (notamment au démarrage)
            clearTimeout(this.panels.timeout);
            this.panels.timeout = null;
          }
        },
        closePanel: function (panel) {
          this.panels.all[panel] = false;
          this.panels.current = '';

          if (panel == 'help') {
            this.settings.showHelpOnLoad = false;
          }
          if (panel == 'summary') {
            this.clipboardCopied = false;
          }
          if (panel == 'settings') {
            this.saveSettings();
          }
          this.appClasses[3] = 'panel-closed';
        },
        closeCurrentPanel: function () {
          this.closePanel(this.panels.current);
        },


      // messages "toast"
        setToastMsg: function (msg, timeout) {
          this.toastMsg.msg = msg;
          let self = this;
          timeout = timeout ? timeout : this.toastMsg.timeout;
          clearTimeout(this.toastMsg.t);
          this.toastMsg.t = setTimeout(function() {
            self.toastMsg.msg = '';
          }, timeout);
        },
        setRandomToast: function (voc, time) {
          if (! voc) {return;}
          let msg = voc[Math.floor(Math.random() * voc.length)];
          this.setToastMsg(msg, time);
          return msg;
        },


      // traitement données du jeu et de l'utilisateur
        udpateWord: function () {
          // -> mode normal uniquement
          // mettre à jour le mot courant (ligne) avec les données du serveur
          let hints = this.serverData.data;
          let word = [];
          for (let i = 0; i < this.wordLength; i++) {
            word.push(hints[i]);
          }
          this.words[this.game.currentLine] = word;
          this.game.currentLine++;
          this.userInput = '';
          this.keyHighlight();

          // jeu terminé
          if (this.serverData.result == 'success' || this.serverData.result == 'failure') {
            this.game.finished = true;
            this.game.answer = this.serverData.answer;
            this.settings.showHelpOnLoad = false;
            if (this.serverData.result == 'success') {
              this.game.success = true;
              let msgLevel = 3;
              switch (this.game.currentLine) {
                case 1:
                case 2:
                case 6:
                  msgLevel = this.game.currentLine;
                  break;
              }
              let msg = this.setRandomToast(this.congratMsgs[msgLevel]);
              this.congratMsg = msg;
            } else {
              this.setRandomToast(this.failureMsgs);
            }
            this.saveStats();
            // mettre un timeout pour laisser le temps de voir le résultat
            let self = this;
            setTimeout(function() {
              self.checkFinished();
            }, 4000);
          }

          this.checkKeys();
          this.saveLocalData();
        },
        udpateData: function () {
          // mettre à jour le modèle de données en fonction de la saisie utilisateur
          // donc décomposer/stocker le mot saisi (dans 'words') et faire apparaître les lettres à l'écran
          this.hasWildcards = false;
          let w = this.userInput;
          let word = [];
          // on traite chaque lettre du mot saisi par l'utilisateur
          for (let i = 0; i < this.wordLength; i++) {
            if (w[i]) {
              word.push([w[i], '']);
              if (w[i] == '*') {
                this.hasWildcards = true;
              }
            } else {
              word.push(['', '']);
            }
          }

          if (this.settings.infinityMode) {
            this.words[this.infinity.currentLine] = word;
          } else {
            this.words[this.game.currentLine] = word;
          }
        },
        checkFinished: function () {
          if (this.game.finished) {
            this.congratMsg = this.congratMsgs[3][Math.floor(Math.random() * this.congratMsgs[3].length)];
            this.openPanel('summary');
          }
        },
        saveStats: function () {
          // -> mode normal uniquement
          this.stats.games++;
          let tries = this.game.currentLine - 1;
          if (this.game.success) {
            this.stats.victories++;
            this.stats.currentStreak++;
            if (this.stats.currentStreak > this.stats.bestStreak) {
              this.stats.bestStreak = this.stats.currentStreak;
            }
          } else {
            tries++;
            this.stats.currentStreak = 0;
          }

          let pc = 100 / (this.stats.games / this.stats.victories);
          this.stats.victoriesPc = parseFloat(pc.toFixed(2));

          this.stats.tries[tries]++;
          this.stats.history.push([this.serverData.date, tries]);

          let bestTries = 0;
          for (let i = 0; i < this.stats.tries.length; i++) {
            let t = this.stats.tries[i];
            if (t > bestTries) {
              bestTries = t;
              this.stats.bestTriesIndex = i;
            }
          }
        },
        resetWords: function () {
          this.words = [];
          for (let i = 0; i < this.nbTries; i++) {
            let word = [];
            for (let j = 0; j < this.wordLength; j++) {
              let letter = ['', ''];
              word.push(letter);
            }
            this.words.push(word);
          }
        },
        resetGame: function (restore) {
          if (this.settings.infinityMode) {
            this.userInput = '';
            this.resetWords();
            this.resetKeys();
            this.udpateData();
          } else {
            if (restore) {
              // lorsqu'on bascule du mode infini au mode normal
              this.userInput = '';
              this.game.id = null;
              this.game.answer = '_____';
              this.game.finished = false;
              this.game.success = false;
              this.game.currentLine = 0;
              this.resetWords();
              this.resetKeys();
              this.udpateData();
            } else {
              this.userInput = '';
              this.game.id = null;
              this.game.answer = '_____';
              this.game.finished = false;
              this.game.success = false;
              this.game.currentLine = 0;
              this.resetWords();
              this.resetKeys();
              this.saveLocalData();
              this.udpateData();
            }
          }
        },


      // gestion du clavier
        keyPressed: function (value) {
          if (this.isDeletingWord) return;
          value = value.toUpperCase();

          // touches spéciales
          switch (value) {
            case 'ENTER':
              this.sendInput();
              return;
            case 'BACKSPACE':
              this.erase();
              return;
            case 'ESCAPE':
              if (this.settings.infinityMode && this.infinity.finished) {
                this.infinityNext();
              } else {
                this.deleteWord();
              }
              if (this.panels.current && ! this.panels.layout2) {
                this.closeCurrentPanel();
              }
              return;
          }

          // conditions d'arrêt
          if (this.settings.infinityMode) {
            if (this.infinity.finished) return;
          } else {
            if (this.game.finished) return;
          }
          if (this.userInput.length >= this.wordLength) return;

          // touches normales (lettres)
          this.userInput += value;
          if (value == '*') {
            this.keys['*'] = 9;
          } else {
            this.keyHighlight();
          }
          this.udpateData();
        },
        keyHighlight: function () {
          // 1. réinitiliser
          for (const key in this.keys) {
            if (this.keys[key] >= 10) {
              this.keys[key] -= 10;
            } else if (this.keys[key] == 9) {
              delete this.keys[key];
            }
          }
          // 2. mettre en surbrillance
          let word = this.userInput.split('');
          let self = this;
          word.forEach(letter => {
            let c = self.keys[letter];
            if (c || c === 0) {
              if (c < 9) {
                self.keys[letter] += 10;
              }
            } else {
              self.keys[letter] = 9;
            }
          });
        },
        erase: function () {
          if (this.userInput.length < 1) return;
          this.userInput = this.userInput.substr(0, this.userInput.length - 1);
          this.udpateData();
          this.keyHighlight();
        },
        deleteWord: function () {
          this.isDeletingWord = true;
          if (this.userInput) {
            this.erase();
            this.udpateData();
            let self = this;
            setTimeout(() => {
              self.deleteWord();
            }, 100);
          } else {
            this.isDeletingWord = false;
          }
        },
        checkKeys: function () {
          for (const word of this.words) {
            for (const letter of word) {
              if (letter[0]) {
                let key = this.keys[letter[0]];
                if (key) {
                  if (key < letter[1]) {
                    this.keys[letter[0]] = letter[1];
                  }
                } else {
                  this.keys[letter[0]] = letter[1];
                }
              }
            }
          }
        },
        resetKeys: function () {
          this.keys = {};
        },
        keyMonitor: function (event) {
          let key = '';
          let input = event.key;
          var alphabet = [];

          for (let i=97; i<123; i++) {
            alphabet.push(String.fromCharCode(i));
          }
          for (let i=65; i<91; i++) {
            alphabet.push(String.fromCharCode(i));
          }

          if (alphabet.includes(input)) {
            key = input;
          } else {
            switch (input) {
              case '*':
              case '.':
              case '-':
              case ' ':
                if (this.settings.showWildcardKey) {
                  key = '*';
                }
                break;
              case 'Enter':
              case 'Escape':
              case 'Backspace':
                key = input;
                break;
            }
          }

          if (key) {
            this.keyPressed(key);
          }
        },


      // settings
        changeSettings: function (setting, value) {
          if (!value) {
            // setting simple (booléen)
            this.settings[setting] = !this.settings[setting];

            if (setting == 'colorBlind') {
              this.setTheme();
            }
            if (setting == 'reducedMotion') {
              this.setTheme();
            }
          } else {
            // setting avec valeur non-booléenne
            switch (setting) {
              case 'keyboardLayout':
                this.setKeyboardLayout(value);
                break;
              case 'theme':
                switch (value) {
                  case 'light':
                  case 'dark':
                    break;
                  default:
                    value = 'dark';
                    break;
                }
                this.settings.theme = value;
                this.setTheme();
                break;
            }
          }
          this.saveSettings(setting);
        },
        setKeyboardLayout: function (layout) {
          switch (layout) {
            case 'QWERTZ':
            case 'QWERTY':
            case 'AZERTY':
              break;
            default:
              layout = 'QWERTZ';
              break;
          }
          this.settings.keyboardLayout = layout;
        },
        setTheme: function () {
          let theme = this.settings.theme;
          this.appClasses[0] = 'theme-' + theme;
          this.appClasses[1] = this.settings.colorBlind ? 'color-blind' : '';
          this.appClasses[2] = this.settings.reducedMotion ? 'reduced-motion' : '';
        },


      // post-game (partage et countdown)
        shareWordle: function () {
          // -> mode normal uniquement
          let result = this.game.currentLine;
          if (result == 6 && ! this.game.success) {
            result = '💀';
          }

          let k0 = '⬛';
          let k1 = '🟨';
          let k2 = '🟩';
          if (this.settings.theme == 'light') {
            k0 = '⬜';
          }
          if (this.settings.colorBlind) {
            k1 = '🟧';
            k2 = '🟦';
          }

          let text = '';
          text += `WORDLE·FR #${this.game.id} (${result}/6)\n\n`;

          for (let i = 0; i < this.game.currentLine; i++) {
            const word = this.words[i]
            for (const letter of word) {
              if      (letter[1] == 0) {text += k0;}
              else if (letter[1] == 1) {text += k1;}
              else if (letter[1] == 2) {text += k2;}
            }
            text += `\n`;
          }

          text += `\n`;
          text += this.gameUrl;

          this.$clipboard(text);
          this.clipboardCopied = true;
        },
        initCountdown: function (c) {
          this.countdown.time = c;
          if (this.countdown.timeout) {
            clearInterval(this.countdown.timeout);
          }
          this.setCountdown();

          let self = this;
          this.countdown.timeout = setInterval(() => {
            self.setCountdown();
          }, 1000);
        },
        setCountdown: function () {
          if (this.countdown.time <= 0) {
            this.cancelCountdown();
            this.checkNewWordle();
            return;
          }
          let c = this.countdown.time;

          let text = '';
          let hours = 0;
          let minutes = 0;
          let seconds = 0;

          hours = Math.floor(c / (60 * 60));
          c -= hours * 60 * 60;
          minutes = Math.floor(c / 60);
          c -= minutes * 60;
          seconds = c;

          if (hours < 10) {
            text += '0' + hours + ':';
          } else {
            text += hours + ':';
          }
          if (minutes < 10) {
            text += '0' + minutes + ':';
          } else {
            text += minutes + ':';
          }
          if (seconds < 10) {
            text += '0' + seconds;
          } else {
            text += seconds;
          }

          this.countdown.text = text;
          this.countdown.time--;
        },
        cancelCountdown: function () {
          clearInterval(this.countdown.timeout);
          this.countdown.time= 0;
          this.countdown.text= '';
          this.countdown.timeout= null;
        },


      // utilitaires
        checkViewport: function () {
          const vw = Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0);
          this.panels.layout2 = vw >= 1400 ? true : false;
        },


      // Mode infini - autre mode de jeu
        // - autre requête
        // - on reçoit 100 mots et tout est géré côté client
        // - pas de partage
        // - stats séparées
        infinityStart: function () {
          this.resetGame();
          this.infinityGet();
          if (this.panels.layout2) {
            this.openPanel('summary');
          } else {
            this.closePanel('settings');
          }
        },
        infinityStop: function () {
          this.infinity.words = [];
          this.infinity.wordle = '';
          this.infinity.currentLine = 0;
          this.infinity.answer = '_____';
          this.infinity.finished = false;
          this.infinity.success = false;
          this.infinity.previousSuccess = false;

          this.resetGame(true);
          this.getLocalData();
          this.checkNewWordle();
        },
        infinityGet: function () {
          let self = this;
          const axios = require('axios').default;

          axios.get(this.serverRoot + 'server.php?infinity')
            .then(function (response) {
              let data = response.data;
              if (data) {
                self.infinity.words = data.words;
                self.infinityNext();
              }
            })
            .catch(function (error) {
              console.log(error);
            })
        },
        infinityNext: function () {
          if (this.infinity.words.length == 0) {
            this.infinityGet();
            return;
          }

          // réinitialiser le plateau
          this.resetGame();
          this.infinity.finished = false;
          // on garde quand même l'affichage du mot précédent
          if (this.infinity.wordle) {
            this.infinity.answer = this.infinity.wordle;
            this.setRandomToast(this.startMsgs);
          } else {
            this.setToastMsg('Go !');
          }
          this.infinity.currentLine = 0;
          this.infinity.wordle = this.infinity.words.pop();
          // console.log(this.infinity.wordle);
          if (! this.panels.layout2) {
            this.closePanel('summary');
          }
        },
        infinityCheck: function () {
          if (this.infinity.finished) return;

          let word = this.userInput;
          if (word.length != this.wordLength) {
            this.setToastMsg("Veuillez saisir un mot de 5 lettres.");
            return;
          }
          if (~word.indexOf('*')) {
            this.setToastMsg("Le mot ne peut pas contenir d'astérisque (*).");
            return;
          }

          let self = this;
          const axios = require('axios').default;

          axios.get(this.serverRoot + 'server.php?checkWord=' + word)
            .then(function (response) {
              let r = response.data;
              if (r) {
                // mot validé
                self.infinityTest();
              } else {
                self.setToastMsg("Ce mot n'est pas reconnu.");
                self.deleteWord();
              }
            })
            .catch(function (error) {
              console.log(error);
            });
        },
        infinityTest: function () {
          let w1 = this.userInput;
          let w0 = this.infinity.wordle;
          let code2 = 0;
          let hints = [];

          // cf. code PHP pour explications sur l'algorithme (game.php)
          // 1. traitement "codes 2"
          for (let i = 0; i < w1.length; i++) {
            let code = 0;
            for (let j = 0; j < w0.length; j++) {
              if (w1[i] == w0[j] && i == j) {
                code = 2;
                code2++;
                break;
              }
            }
            hints[i] = [w1[i], code];
            if (code == 2) {
              w1 = w1.substring(0, i) + '_' + w1.substring(i+1);
              w0 = w0.substring(0, i) + '_' + w0.substring(i+1);
            }
          }

          // 2. traitement "codes 1/0".
          for (let i = 0; i < w1.length; i++) {
            if (w1[i] == '_') {continue;}
            let code = 0;

            for (let j = 0; j < w0.length; j++) {
              if (w0[j] == '_') {continue;}
              if (w1[i] == w0[j]) {
                code = 1;
                w0 = w0.substring(0, j) + '_' + w0.substring(j+1);
                break;
              }
            }

            hints[i][1] = code;
          }

          this.words[this.infinity.currentLine] = hints;
          this.infinity.currentLine++;
          this.userInput = '';
          this.keyHighlight();
          this.checkKeys();

          if (code2 >= 5) {
            // gagné !
            let msgLevel = 3;
            switch (this.infinity.currentLine) {
              case 1:
              case 2:
              case 6:
                msgLevel = this.infinity.currentLine;
                break;
            }
            let msg = this.setRandomToast(this.congratMsgs[msgLevel]);
            this.congratMsg = msg;
            this.infinity.finished = true;
            this.infinity.success = true;
            this.infinity.previousSuccess = true;
          } else if (this.infinity.currentLine >= 6) {
            // perdu !
            this.setRandomToast(this.failureMsgs);
            this.infinity.finished = true;
            this.infinity.success = false;
            this.infinity.previousSuccess = false;
          } else {
            // on continue !
          }

          if (this.infinity.finished) {
            this.infinity.answer = this.infinity.wordle;
            this.infinityStats();
            this.saveLocalData();
            // mettre un timeout pour laisser le temps de voir le résultat
            let self = this;
            setTimeout(function() {
              self.openPanel('summary');
            }, 1500);
          }
        },
        infinityStats: function () {
          this.stats.infinity.games++;
          let tries = this.infinity.currentLine - 1;
          if (this.infinity.success) {
            this.stats.infinity.victories++;
            this.stats.infinity.currentStreak++;
            if (this.stats.infinity.currentStreak > this.stats.infinity.bestStreak) {
              this.stats.infinity.bestStreak = this.stats.infinity.currentStreak;
            }
          } else {
            tries++;
            this.stats.infinity.currentStreak = 0;
          }

          let pc = 100 / (this.stats.infinity.games / this.stats.infinity.victories);
          this.stats.infinity.victoriesPc = parseFloat(pc.toFixed(2));

          this.stats.infinity.tries[tries]++;
          this.stats.infinity.history.push([Date.now(), tries]);

          let bestTries = 0;
          for (let i = 0; i < this.stats.infinity.tries.length; i++) {
            let t = this.stats.infinity.tries[i];
            if (t > bestTries) {
              bestTries = t;
              this.stats.infinity.bestTriesIndex = i;
            }
          }
        },
    },
    mounted () {
      let self = this;
      document.addEventListener('visibilitychange', function() {
        if (document.visibilityState == 'visible') {
          if (!self.settings.infinityMode) {
            self.checkNewWordle();
          }
        }
      });
      window.addEventListener('keyup', this.keyMonitor);
      window.addEventListener('resize', this.checkViewport);
    },
    beforeMount: function () {
      this.resetWords();
      this.getLocalData();
      this.setTheme();
      this.checkNewWordle();
      this.checkKeys();
      this.checkViewport();

      let self = this;
      this.panels.timeout = setTimeout(function() {
        if (! self.settings.infinityMode) {
          self.checkFinished();
        }
      }, 2000);

      if (this.settings.showHelpOnLoad) {
        this.openPanel('help');
      }
      if (this.settings.infinityMode) {
        this.infinityStart();
      }
      if (! this.stats.infinity) {
        this.stats.infinity = this.infinity.statsDefault;
      }
    }
  }
</script>

<style lang="scss">
  @import './assets/app.scss';
</style>
