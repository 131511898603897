import Vue from 'vue';
// import Vuex from 'vuex';
import App from './App.vue';
import Clipboard from "v-clipboard";

// Vue.use(Vuex);
Vue.use(Clipboard);
Vue.config.productionTip = false;

// const store = new Vuex.Store({
//   state: {
//     count: 0,
//   },
//   mutations: {
//     increment (state) {
//       state.count++;
//     }
//   },
// });

new Vue({
  render: h => h(App),
  // store,
}).$mount('#app');
