<template>
  <span :class="classes">
    {{ letter[0] }}
    <transition name="fade">
      <span class="hint" v-if="showHint">
        {{ hintTxt }}
      </span>
    </transition>
  </span>
</template>

<script>
export default {
  name: 'UserEntry',
  props: {
    order: Number,
    letter: Array,
    currentLine: Number,
    hint: String,
    showHint: Boolean,
  },
  computed: {
    classes: function () {
      let cl = ['letter'];
      cl.push('l' + this.order);
      cl.push('v' + this.letter[1]);
      return cl;
    },
    hintTxt: function () {
      return this.hint == '_' ? '' : this.hint;
    },
  },
}
</script>
