<template>
  <transition name="fade">
    <div class="toast-msg" v-if="toastMsg.msg">
      <div class="inner" v-html="toastMsg.msg"></div>
    </div>
  </transition>
</template>

<script>
export default {
  name: 'ToastMsg',
  props: {
    toastMsg: Object,
  },
}
</script>
