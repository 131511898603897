<template>
  <div :class="classes">
    <UserLetter
      v-for="i in 5"
      :key="i"
      :order="i"
      :letter="words[line][i-1]"
      :currentLine="currentLine"
      :hint="hints[i-1]"
      :showHint="showHint"
    />
  </div>
</template>

<script>
  import UserLetter from './UserLetter.vue';

  export default {
    name: 'UserEntry',
    components: {
      UserLetter
    },
    props: {
      line: Number,
      words: Array,
      currentLine: Number,
      input: String,
    },
    computed: {
      classes: function () {
        let cl = ['entry'];
        if (this.line == this.currentLine-1) {
          cl.push('current');
        } else if (this.line == this.currentLine) {
          cl.push('next');
        }
        return cl;
      },
      hints: function () {
        let h = ['_','_','_','_','_'];
        if (this.currentLine) { // force trigger recomputing
          // en théorie cette condition n'est pas nécessaire MAIS sa présence
          // force Vue à recalculer cette propriété (hints).
          // Si on l'enlève, la propriété n'est plus mise à jour dynamiquement
          // lorsqu'on passe d'une ligne à l'autre.
          for (const word of this.words) {
            for (let i = 0; i < word.length; i++) {
              const letter = word[i];
              if (letter[1] == 2) {
                h[i] = letter[0];
              }
            }
          }
        }
        if (! h.includes('_')) {
          // ne pas afficher d'indice si le mot a été trouvé
          h = ['_','_','_','_','_'];
        }
        return h.join('');
      },
      showHint: function () {
        let show = false;
        if (this.line == this.currentLine && this.input === '') {
          show = true;
        }
        return show;
      }
    },
  }
</script>
