<template>
  <div v-if="showWildcard" :class="classes" @click="press">
    <template v-if="k != 'ENTER' && k != 'BACKSPACE'">
      {{ k }}
    </template>
    <template v-if="k == 'ENTER'">
      <svg class="svg-icon"><use xlink:href="#svg-check" /></svg>
    </template>
    <template v-if="k == 'BACKSPACE'">
      <svg class="svg-icon"><use xlink:href="#svg-backspace" /></svg>
    </template>
  </div>
</template>

<script>
export default {
  name: 'KeyboardKey',
  props: {
    k: String,
    c: Number,
    wildcard: Boolean,
  },
  computed: {
    classes: function () {
      let cl = [];
      if (this.c >= 0) {
        cl.push('k'+this.c);
      } else {
        cl.push('k');
      }
      switch (this.k) {
        case '*':
          cl.push('fn wildcard');
          break;
        case 'ENTER':
          cl.push('fn submit');
          break;
        case 'BACKSPACE':
          cl.push('fn erase');
          break;
      }
      return cl;
    },
    showWildcard: function () {
      return this.k != '*' || this.wildcard;
    }
  },
  methods: {
    press: function () {
      this.$emit('keyPressed', this.k)
    },
  }
}
</script>
